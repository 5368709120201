<template>
  <v-simple-table
    :height="control.size.height"
    :style="control.style"
    :dense="control.config.dense"
    style="background: transparent"
  >
    <template v-slot:default>
      <thead>
        <tr v-if="items.length > 0">
          <th
            v-for="(header, i) in items[0]"
            :key="i"
            v-text="header"
            :style="cellStyle"
          ></th>
        </tr>
      </thead>
      <tbody v-if="items.length > 1">
        <tr
          v-for="(row, rowIndex) in items"
          :key="rowIndex"
          v-show="rowIndex != 0"
        >
          <td
            v-for="(cell, cellIndex) in row"
            :key="cellIndex"
            v-text="cell"
            :style="cellStyle"
          ></td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  props: { control: Object },
  computed: {
    items() {
      let result = [];
      if (this.control.config && this.control.config.csv) {
        let lines = this.control.config.csv.split("\n");
        for (let i in lines) {
          let line = lines[i];
          if (line) result.push(line.split(","));
        }
      }
      return result;
    },
    cellStyle() {
      let result = {};
      result["color"] = this.control.style["color"];
      result["border-color"] = this.control.style["border-color"];
      result["font-size"] = this.control.style["font-size"];
      return result;
    },
  },
};
</script>
